import React from "react"
import { Grid, Typography } from "@material-ui/core"
import Animate from "../animations/animate"
import PropTypes from "prop-types"

const FeatureCard = ({
  image,
  textAnimation,
  imageRotate,
  title,
  text,
  direction,
}) => {
  return (
    <Grid container direction={direction} justify="center">
      <Grid container item md={7} sm={12}  alignItems="center">
        <Grid item >
          <Animate animation={textAnimation}>
            <Typography
              component="h2"
              className="heading text-left-mb-center purple"
            >
              {title}
            </Typography>
            <Typography component="p" className="paragraph text-left-mb-center">
              {text}
            </Typography>
          </Animate>
        </Grid>
      </Grid>
      <Grid item md={5} sm={12} className="order-one">
        <Animate src={image} alt={title} animation={imageRotate} />
      </Grid>
    </Grid>
  )
}

FeatureCard.propTypes = {
  image: PropTypes.any.isRequired,
  textAnimation: PropTypes.string.isRequired,
  imageRotate: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  direction: PropTypes.string.isRequired,
}

export default FeatureCard
