import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import Section from "../Section"
import AnimateMoveUp from "../../components/animations/animateMoveUp"
import Phone from "../../images/howItWorks/_phone.png"
import AnimateMoveDown from "../../components/animations/animateMoveDown"

const useStyles = makeStyles(() => ({}))

const MovingPhones = () => {
  return (
    <Section>
      <Grid container direction={"row"} style={{ minHeight: "100vh" }}>
        <Grid item md={3} style={{ minHeight: "80vh" }}>
          <br />
          <br />
          <AnimateMoveUp src={Phone} alt="phone" rotate={0} />
        </Grid>
        <Grid item md={3}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <AnimateMoveDown src={Phone} alt="phone" rotate={0} />
        </Grid>
        <Grid item md={3}>
          <br />
          <AnimateMoveUp src={Phone} alt="phone" rotate={0} />
        </Grid>
        <Grid item md={3}>
          <br />
          <br />
          <AnimateMoveDown src={Phone} alt="phone" rotate={0} />
        </Grid>
      </Grid>
    </Section>
  )
}
export default MovingPhones
