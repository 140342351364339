import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import FeatureCard from "../components/features/featureCard"
import Image from "../images/features/3.png"
import { Grid, Typography, Button } from "@material-ui/core"
import About from "../components/about"
import TestimonialBox from "../components/reviewBox"
import GoldBanner from "../components/gold/goldBanner"
import MovingPhones from "../components/gold/movingPhones"

const Gold = () => {
  return (
    <Layout>
      <SEO title="RajaRaani Gold" />
      <GoldBanner/>
      <Section>
        <Typography component="p">
          Get married quicker with muzmatch Gold. Our Gold subscription unlocks
          <br />
          features designed to get you married quicker.
        </Typography>
      </Section>

      <Section>
        <Grid container direction="row" justify="center">
          <FeatureCard
            image={Image}
            textAnimation={"right"}
            imageRotate={"rotateRight"}
            title={"Instant Chat"}
            text={"Send direct messages to members"}
            direction={"row"}
          />
          <FeatureCard
            image={Image}
            textAnimation={"left"}
            imageRotate={"rotateLeft"}
            title={"No adverts"}
            text={"Enjoy Raja Rani with no adverts"}
            direction={"row-reverse"}
          />
        </Grid>
      </Section>
      <Section colorBG={"#FDF1CC"}>
        <Grid container direction="row" justify="center" alignItems="center">
          <TestimonialBox />
          <TestimonialBox />
          <TestimonialBox />
        </Grid>
      </Section>
      <Section >
        <Grid container direction="row" justify="center">
          <FeatureCard
            image={Image}
            textAnimation={"right"}
            imageRotate={"rotateRight"}
            title={"24/7 monitoring"}
            text={
              "24/7 monitoring and manual matching of the members according to their searches"
            }
            direction={"row"}
          />
          <FeatureCard
            image={Image}
            textAnimation={"left"}
            imageRotate={"rotateLeft"}
            title={"Blocking option"}
            text={"Blocking option"}
            direction={"row-reverse"}
          />
        </Grid>
      </Section>
            <MovingPhones/>
      <Section>
        <Typography>
          Get married quicker with muzmatch Gold. Our Gold subscription unlocks
          <br />
          features designed to get you married quicker
        </Typography>
        <Button
          style={{
            backgroundColor: "#E9A939",
            color: "#fff",
            padding: "10px 20px",
            marginTop: "20px",
            borderRadius: "30px",
          }}
        >
          Upgrade to Gold
        </Button>
      </Section>
      <About />
    </Layout>
  )
}
export default Gold
