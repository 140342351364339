import React from "react"
import { makeStyles, Typography, Grid } from "@material-ui/core"
import Stars from "../images/gold/stars.png"
import UserImage from "../images/gold/user-ilyas.jpg"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100px",
    textAlign: "center",
    padding:'10px 15px'
  },
  stars: {},
  text: {
    marginTop: "30px"
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent:'center',
    marginTop: "30px"
  },
  avatarName: {
    textAlign: "left",
    alignItems:'center',
    display: "flex",
    marginLeft: '5px'
  },
  avatarImage: {
    width: "10%",
    
    '& img':{
        width: "100%",
        padding: '0',
        margin:0,
        borderRadius:'50%'
    }
  },
}))

const TestimonialBox = () => {
  const classes = useStyles()
  return (
    <Grid item  md={4} sm={10} className={classes.root}>
      <div>
        <img src={Stars} alt="stars" />
      </div>
      <div className={classes.text}>
        <Typography>
          “I was never a fan of dating apps, but I found my better half here.”
        </Typography>
      </div>
      <div className={classes.avatar}>
        <div className={classes.avatarImage}><img src={UserImage} alt="user avatar"/></div>
        <div className={classes.avatarName}>Ilyas Ali Yonis</div>
      </div>
    </Grid>
  )
}

export default TestimonialBox
