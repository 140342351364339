import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import Banner from "../global/banner"
import logoHeart from "../../images/logos/heart_logo_yellow.png"
import logoText from "../../images/logos/logo_justText.png"
import BannerImage from "../../images/gold/banner_bg.jpeg"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  logoContainer: {
    textAlign: "center",
  },
  logo_heart: {
    width: "100px",
  },
  logo_text: {
    width: "200px",
  },
}))

const GoldBanner = () => {
  const classes = useStyles()
  return (
    <Banner image={BannerImage} minHeight={400}>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img
            src={logoHeart}
            alt=" rajaarani logo"
            className={classes.logo_heart}
          />
          <br />
          <img
            src={logoText}
            className={classes.logo_text}
            alt="rajaarani Gold Logo"
          />
          <br />
          <Typography component="h1" className="gold">
            GOLD
          </Typography>
        </div>
      </div>
    </Banner>
  )
}
export default GoldBanner
