import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "400px",
    backgroundColor: "rgb(0, 0, 0)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    boxShadow: "0 0 5px 5px rgba(0,0,0,0.2)",
  },
  overlay: {
    position: "absolute",
    width: "100% !important",
    height: "100% !important",
    background: "rgba(0,0,0,0.4)",
    top: "0",
    right: "0",
    zIndex: 1,
  },
  content: {
    zIndex: 10,
    width: "100%",
  },
}))

const Banner = ({ image, minHeight, children }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${image})`,
        minHeight: `${minHeight}px`,
      }}
    >
      <div className={classes.overlay}></div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  minHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default Banner
