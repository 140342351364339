import React from "react"
import AnimateLeft from "./AnimateLeft"
import PropTypes from "prop-types"
import AnimateRight from "./AnimateRight"
import AnimateRotateRight from "./AnimateRotateRight"
import AnimateRotateLeft from "./AnimateRotateLeft"
import SlideUp from "./slideUp"

const Animate = ({ animation, children, src, ...props }) => {
  if (animation === "left") {
    return <AnimateLeft>{children}</AnimateLeft>
  } else if (animation === "right") {
    return <AnimateRight>{children}</AnimateRight>
  } else if (animation === "rotateRight") {
    return (
      <AnimateRotateRight
        src={src}
        alt={props.alt}
        className="image-container"
      />
    )
  } else if (animation === "rotateLeft") {
    return (
      <AnimateRotateLeft
        src={src}
        alt={props.alt}
        className="image-container"
      />
    )
  } else if (animation === "slideUp") {
    return <SlideUp>{children}</SlideUp>
  } else {
    return <div>{children}</div>
  }
}

Animate.propTypes = {
  animation: PropTypes.string.isRequired,
  children: PropTypes.node,
  src: PropTypes.string,
}

export default Animate
